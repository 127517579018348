<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section 6 <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >

                <base-material-card>
                    <template v-slot:heading>
                      <div class="display-2 font-weight-light">
                      Section 6: Glass & Mirror Inc.
                      </div >
                      <div class="display- font-weight-light"> 
                      10001 Baltimore Street N.E. Blaine, 55449	763-784-1705
                      </div>
                      <div class="display- font-weight-light">
                      (Time Frame- 6 Weeks)
                      </div>
                    </template>
                    <v-form>
                        <v-container class="py-0">
                          <v-row>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner(s)"
                                    color="black"
                                    v-model = homeowners
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Address & Legal"
                                    color="black"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>
                            <v-col
                            cols="16"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Phone"
                                    color="black"
                                    v-model="this.selected_user.HomeNumber"
                                />
                            </v-col>
                            <v-col
                            cols="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Emails"
                                    color="black"
                                    v-model="emails"
                                />
                            </v-col>
                          </v-row>
                            <v-row>
                                <v-col cols="12">
                                  <v-header><strong>MASTER OPTIONAL SHOWER DOOR</strong></v-header>
                                </v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Glass" :items="['No', 'Clear','Obscured','Other','Undecided']" v-model="section.column_5"></v-select></v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Trim" :items="['Frameless','Chrome','ORB','BN','Other','Undecided']" v-model="section.column_32"></v-select></v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="STD Frameless Vanity Mirror Trim" :items="['Chrome','ORB','BN','Other','Undecided']" v-model="section.column_6"></v-select>  	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Homeowner to provide own Mirror" :items="['Yes','No','Undecided']" v-model="section.column_7"></v-select></v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Door" :items="['Yes','No','Undecided']" v-model="section.column_8"></v-select>	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Type of Door"  :items="['Frameless','Framed','Other','Undecided']" v-model="section.column_9"></v-select> 	</v-col>

                                <v-col cols="12">
                                  <v-header><strong>MAIN BATHROOM SHOWER DOOR</strong></v-header>
                                </v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Glass" :items="['NO', 'Clear','Obscured','Other','Undecided']" v-model="section.column_10" ></v-select></v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Trim" :items="['Frameless','Chrome','ORB','BN','Other','Undecided']" v-model="section.column_11"></v-select>  	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="STD Frameless Vanity Mirror Trim" :items="['Chrome','ORB','BN','Other','Undecided']" v-model="section.column_12"></v-select>  	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Homeowner to provide own Mirror" :items="['Yes','No','Undecided']" v-model="section.column_13"></v-select>  			</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Door" :items="['Yes','No','Undecided']" v-model="section.column_14"></v-select>	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Type of Door"  :items="['Frameless','Framed','Other','Undecided']" v-model="section.column_15"></v-select> 	</v-col>

                                <v-col cols="12">
                                  <v-header><strong>ADDITIONAL BATHROOM OPTIONS SHOWER DOOR</strong></v-header>
                                </v-col>
                                <v-col cols="12"><v-text-field :readonly="permissions === 'read:homeowner'" label="Location"  ></v-text-field></v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Glass" :items="['NO', 'Clear','Obscured','Other','Undecided']" v-model="section.column_16"></v-select></v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Trim" :items="['Frameless','Chrome','ORB','BN','Other','Undecided']" v-model="section.column_17"></v-select>  	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="STD Frameless Vanity Mirror Trim" :items="['Chrome','ORB','BN','Other','Undecided']" v-model="section.column_18"></v-select>  	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Homeowner to provide own Mirror" :items="['Yes','No','Undecided']" v-model="section.column_19"></v-select>  			</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Door" :items="['Yes','No','Undecided']" v-model="section.column_20"></v-select>	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Type of Door"  :items="['Frameless','Framed','Other','Undecided']" v-model="section.column_21"></v-select> 	</v-col>

                                <v-col cols="12">
                                  <v-header><strong>ADDITIONAL BASEMENT BATHROOM OPTIONS</strong></v-header>
                                </v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Glass" :items="['NO', 'Clear','Obscured','Other','Undecided']" v-model="section.column_22"></v-select></v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Trim" :items="['Frameless','Chrome','ORB','BN','Other','Undecided']" v-model="section.column_23"></v-select>  	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="STD Frameless Vanity Mirror Trim" :items="['Chrome','ORB','BN','Other','Undecided']" v-model="section.column_24"></v-select>  	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Homeowner to provide own Mirror" :items="['Yes','No','Undecided']" v-model="section.column_25"></v-select>  			</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Shower Door" :items="['Yes','No','Undecided']" v-model="section.column_26"></v-select>	</v-col>
                                <v-col cols="4"><v-select :readonly="permissions === 'read:homeowner'" label="Type of Door"  :items="['Frameless','Framed','Other','Undecided']" v-model="section.column_27"></v-select> 	</v-col>
                      <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                           </v-dialog>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
              <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Signature
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">

                         <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 1"
                                     v-model="section.column_28"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                     v-model="section.column_29"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field label="Homeowner 2"
                                     v-model="section.column_30"/>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field type="date"
                                     v-model="section.column_31"/>
                                </v-col>
                           <v-col cols="12" class="text-right">
                        <v-btn color="success" class="mr-0"
                        @click="updateSection">Sign</v-btn>
                    </v-col>
                         </v-row>
                             </v-container>
                          </v-form>
                </base-material-card>

            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import axios from "axios";

export default {
  name: 'Section6',
    data() {
    return {
      home_id: localStorage.getItem("homeid"),
      alert:false,
      dialog: null,
      valid: null,
      staff_email: 'steve@skelleymarketing.com',
      section_id: "6",
      add_vendor_email:'',
      vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
      section: {
        column_1:'',
        column_2:'',
        column_3:'',
        column_4:'',
        column_5:'',
        column_32:'',
        column_6:'',
        column_7:'',
        column_8:'',
        column_9:'',
        column_10:'',
        column_11:'',
        column_12:'',
        column_13:'',
        column_14:'',
        column_15:'',
        column_16:'',
        column_17:'',
        column_18:'',
        column_19:'',
        column_20:'',
        column_21:'',
        column_22:'',
        column_23:'',
        column_24:'',
        column_25:'',
        column_26:'',
        column_27:'',
        column_28:'',
        column_29:'',
        column_30:'',
        column_31:'',
      },
      selected_user: {
          PrimaryEmailAddress:'',
          SecondaryEmailAddress:'',
          PrimaryFirstName: '',
          PrimaryLastName: '',
          SecondaryFirstName: '',
          SecondaryLastName: '',
          Address:'',
          City:'',
          State: '',
          Zip: '',
          BuildStart: '',
          BuildEnd:'',
          CellNumber:'',
          HomeNumber: '',
          WorkPhone:'',
          Role:'',
          Agent:''
      },
    }
  },
  created () {
    this.getSection()
    this.getVendorEmail()
    this.getUserInfo()
    },
    methods:{

        getSection() {
            axios
            .get(process.env.VUE_APP_GET_SECTION_URL,
            {
            params: {
                home_id: this.home_id,
                section_id: this.section_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.section = JSON.parse(result['JsonDetails'])
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
      getUserInfo() {
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.selected_user = result[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
      getVendorEmail () {
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,{})
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
        },
        updateSection(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }
            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
      sectionComplete(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }
            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
                this.sendEmail([this.homeowner_emailid],this.staff_email,"home_section_submit_notification",this.selected_user.PrimaryFirstName, this.section_id)
            })
            .catch(error => {
            console.log(error)
            })
          this.add_vendor_email = Object.assign({}, this.add_vendor_email)
          this.editedIndex = -1
          this.close()
        },
      close () {
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
        },
      sendEmail(to,from,template,content,section){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      }
    },
  computed:{
    homeowners(){
      return `${this.selected_user.PrimaryFirstName} ${this.selected_user.PrimaryLastName} ${this.selected_user.SecondaryFirstName} ${this.selected_user.SecondaryLastName}`
      },
    emails(){
      return `${this.selected_user.PrimaryEmailAddress}, ${this.selected_user.SecondaryEmailAddress}`
      },
    homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions() {
            return localStorage.getItem('permissions')
      },
  }
}
</script>